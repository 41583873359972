import ClevertapHelper from '@/util/ClevertapHelper'
import Vue from 'vue'

export default class EventsHelper {

  constructor() {
    if(!EventsHelper.instance){
      EventsHelper.instance = this;
    }
    return EventsHelper.instance;
  }
  static getInstance () {
    if(EventsHelper.instance) {
      return EventsHelper.instance;
    }
    return new EventsHelper();
  }

  sendEvent (event, params = {}) {
    if (!event) {
      return;
    }
    ClevertapHelper.getInstance().sendEvent(event, params);
    Vue.$gtag.event('event', event);
  }

  trackPageView(path){
    if (!path) {
      return;
    }
    Vue.$gtag.pageview({page_path: path});
  }

  exception(ex) {
    Vue.$gtag.exception(ex);
  }

}