<template>
  <s-main-container>
    <s-fields-wrapper>
      <v-col cols="12">
        <s-title>Dados do responsável</s-title>
      </v-col>
    </s-fields-wrapper>
    <s-fields-wrapper>
      <v-col cols="12">
        <s-label>Nome do responsável legal*</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="name"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <s-label>E-mail do responsável legal*</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="email"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <s-label>CPF do responsável*</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="document"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <s-label>Telefone do responsável*</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="phone"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <p class="edit-data-text font-italic">
          Caso necessite alterar algum dos campos, entre em contato através do
          nosso chat.
        </p>
      </v-col>
      <v-col cols="12">
        <v-btn
          :loading="loading"
          :elevation="0"
          color="secondary"
          class="font-weight-bold"
          block
          x-large
          @click="nextStep"
          >Próximo</v-btn
        >
      </v-col>
    </s-fields-wrapper>
  </s-main-container>
</template>

<script>
import SelfSignUpLabel from '@/components/selfSignUp/Label.vue'
import SelfSignUpTitle from '@/components/selfSignUp/Title.vue'
import SelfSignUpMainContainer from '@/components/selfSignUp/Container.vue'
import SelfSignUpFieldsWrapper from '@/components/selfSignUp/FieldsWrapper.vue'
import EventsHelper from '@/util/EventsHelper'

export default {
  name: 'SelfSignUpResponsibleData',
  data: () => ({
    loading: false,
    name: '',
    email: '',
    document: '',
    phone: ''
  }),
  components: {
    's-label': SelfSignUpLabel,
    's-title': SelfSignUpTitle,
    's-main-container': SelfSignUpMainContainer,
    's-fields-wrapper': SelfSignUpFieldsWrapper
  },
  methods: {
    nextStep() {
      this.$store.dispatch('selfSignUp/nextStep')
      this.$router.push('/self-sign-up/bag-management')
    }
  },
  async mounted() {
    if (this.$store.state.selfSignUp.currentStep != 2) {
      this.$store.commit('selfSignUp/setCurrentStep', 2)
    }

    this.loading = true

    await this.$store.dispatch('selfSignUp/fetchResponsibleData')

    if (this.$store.state.selfSignUp.responsibleData) {
      this.name = this.$store.state.selfSignUp.responsibleData.name
      this.email = this.$store.state.selfSignUp.responsibleData.email
      this.document = this.$store.state.selfSignUp.responsibleData.document
      this.phone = this.$store.state.selfSignUp.responsibleData.phone
    }

    this.loading = false

    EventsHelper.getInstance().sendEvent('access_responsible_information')
  }
}
</script>

<style scoped>
.edit-data-text {
  font-size: 18px;
}
</style>
