import { render, staticRenderFns } from "./BagManagement.vue?vue&type=template&id=77b0a2e3&scoped=true"
import script from "./BagManagement.vue?vue&type=script&lang=js"
export * from "./BagManagement.vue?vue&type=script&lang=js"
import style0 from "./BagManagement.vue?vue&type=style&index=0&id=77b0a2e3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b0a2e3",
  null
  
)

export default component.exports