<template>
  <s-main-container>
    <s-fields-wrapper>
      <v-col cols="12">
        <s-title>Retirada das Sacolas Surpresa</s-title>
      </v-col>
      <v-col cols="12" class="mb-8">
        <span class="text-body-1"
          >No momento, atuamos somente com o modelo de retirada, em que o
          consumidor vai até sua loja retirar sua
          <strong>Sacola Surpresa.</strong></span
        >
      </v-col>
    </s-fields-wrapper>
    <s-fields-wrapper>
      <v-col cols="12">
        <div class="d-flex">
          <s-chip>Retirada</s-chip>
        </div>
      </v-col>
      <v-col cols="12" class="mt-4">
        <p class="text-body-1">
          Conheça os benefícios do consumidor ir até sua loja:
        </p>
        <ul class="s-list text-body-1">
          <li>
            Segundo nossas pesquisas,
            <strong>89% dos consumidores</strong>
            confirmam que preferem retirar sua Sacola Surpresa no local.
          </li>
          <li>
            Segundo nossas pesquisas,
            <strong>45% dos consumidores</strong> costumam comprar outros
            produtos além da Sacola Surpresa para consumir no local durante a
            retirada, o que gera um aumento em sua receita
          </li>
        </ul>
      </v-col>
      <v-col cols="12" class="mt-4">
        <s-label>Observação para retirada</s-label>
        <p class="text-body-1">
          Você gostaria de deixar alguma informação ou instrução que facilite
          para o cliente encontrar o local da retirada?
        </p>
        <v-textarea
          :loading="loading"
          class="mt-2"
          v-model="observation"
          outlined
          hide-details="auto"
          placeholder="Ex.: Balcão lateral, Retirar no Caixa, Informar um atendente"
          no-resize
        ></v-textarea>
      </v-col>
      <v-col cols="12" v-if="error">
        <v-alert dismissible type="error">{{ error }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-btn
          :loading="loading"
          :elevation="0"
          color="secondary"
          class="font-weight-bold"
          block
          x-large
          @click="nextStep"
          >Salvar</v-btn
        >
      </v-col>
    </s-fields-wrapper>
  </s-main-container>
</template>

<script>
import SelfSignUpLabel from '@/components/selfSignUp/Label.vue'
import SelfSignUpTitle from '@/components/selfSignUp/Title.vue'
import SelfSignUpMainContainer from '@/components/selfSignUp/Container.vue'
import SelfSignUpFieldsWrapper from '@/components/selfSignUp/FieldsWrapper.vue'
import SelfSignUpChip from '@/components/selfSignUp/Chip.vue'
import EventsHelper from '@/util/EventsHelper'

export default {
  name: 'SelfSignUpSellingModel',
  data: () => ({
    error: null,
    loading: false,
    observation: ''
  }),
  components: {
    's-label': SelfSignUpLabel,
    's-title': SelfSignUpTitle,
    's-main-container': SelfSignUpMainContainer,
    's-fields-wrapper': SelfSignUpFieldsWrapper,
    's-chip': SelfSignUpChip
  },
  methods: {
    async nextStep() {
      this.loading = true
      try {
        await this.$store.dispatch('selfSignUp/submitSellingModel', {
          observation: this.observation
        })

        this.$store.dispatch('selfSignUp/nextStep')
        this.$router.push('/self-sign-up/branding')
      } catch (e) {
        this.error =
          'Ocorreu um erro ao salvar as informações. Por favor, tente novamente.'
        this.loading = false
      }
    }
  },
  mounted: async function () {
    if (this.$store.state.selfSignUp.currentStep != 5) {
      this.$store.commit('selfSignUp/setCurrentStep', 5)
    }
    this.loading = true

    await this.$store.dispatch('selfSignUp/fetchSellingModel')

    if (this.$store.state.selfSignUp.sellingModel) {
      this.observation = this.$store.state.selfSignUp.sellingModel.observation
    }

    this.loading = false

    EventsHelper.getInstance().sendEvent('access_information_selling_model')
  }
}
</script>

<style scoped>
.edit-data-text {
  font-size: 18px;
}

.s-list {
  list-style: disc;
}
</style>
