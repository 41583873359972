import 'clevertap-web-sdk'

const ACCOUNT_ID = 'TEST-75W-9R6-9R7Z'
export default class ClevertapHelper {
  constructor () {
    if(!ClevertapHelper.instance) {
      ClevertapHelper.identified = false;
      ClevertapHelper.configured = false;
      ClevertapHelper.instance = this;
    }
    return ClevertapHelper.instance;
  }

  static getInstance () {
    if(ClevertapHelper.instance){
      return ClevertapHelper.instance;
    }
    return new ClevertapHelper();
  }

  configure() {
    if(ClevertapHelper.configured) {
      return;
    }
    clevertap.privacy.push({ useIP: false })
    clevertap.init(ACCOUNT_ID, 'us1', '')
    clevertap.setLogLevel(3)
    clevertap.spa = true
    ClevertapHelper.configured = true
  }

  identifyUser(params) {
    if(ClevertapHelper.identified){
      return;
    }
    const {email, identity, name} = params;
    if (!email || !identity) {
      console.error('user cannot be identified');
    }
    console.log(params)
    clevertap.onUserLogin.push({
      Site: {
        Name: name,
        Identity: identity,
        Email: email,
        ...params
      }
    })
    ClevertapHelper.identified = true;
  }

  sendEvent(eventName, payload) {
    clevertap.event.push(eventName, payload);
  }
}
