<script>
import EventsHelper from '@/util/EventsHelper'

export default {
  name: 'KrafBagRequestForm',
  components: {},
  props: {
    partner: {
      require: true
    }
  },
  data() {
    return {
      successMessage: '',
      errorMessage: '',
      valid: true,
      receivedBy: null,
      bagMediumRequested: true,
      nameRules: [
        (v) => !!v || 'Obrigatório'
        // (v) => (v && v.length <= 10) || 'Name must be less than 10 characters'
      ]
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.sendKraftBagRequest()
      }
      EventsHelper.getInstance().sendEvent('order_bags')
    },
    clearMessages() {
      this.successMessage = ''
      this.errorMessage = ''
    },
    sendKraftBagRequest() {
      return this.$http
        .post(`/v1/partners/${this.partner.id}/kraft_bag_requests`, {
          request_user_id: this.$store.getters.usuarioLogado.id,
          request_user_email: this.$store.getters.usuarioLogado.email,
          received_by: this.receivedBy,
          bag_medium_requested: this.bagMediumRequested
        })
        .then((response) => {
          this.successMessage = 'Enviado!'
          return response.data
        })
        .catch((error) => {
          this.errorMessage = error.response.data
        })
        .finally(() => setTimeout(this.clearMessages, 15000))
    }
  }
}
</script>

<template>
  <div>
    <v-card style="border-radius: 10px" class="pa-5">
      <v-alert type="success" v-if="successMessage !== ''"
        >Entrega em até 5 dias. Combata o desperdício! Caso esteja sem
        embalagem, aproveite para utilizar a de seu estabelecimento e divulgar
        sua marca.</v-alert
      >
      <v-alert type="error" v-if="errorMessage !== ''">
        <p>{{ this.errorMessage }}</p>
      </v-alert>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="ma-0">
          <v-col class="ma-0 pa-0">
            <v-text-field
              v-model="receivedBy"
              label="Responsável pelo Recebimento"
              :rules="nameRules"
              required
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            ><v-btn
              color="primary"
              elevation="4"
              :disabled="!valid"
              @click="validate"
              >ENVIAR SOLICITAÇÃO</v-btn
            ></v-col
          >
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<style scoped>
@import './KrafBagRequestForm.scss';
</style>
