<template>
  <s-main-container>
    <s-fields-wrapper>
      <v-col cols="12">
        <s-title>Chegou a hora de personalizar sua loja!</s-title>
      </v-col>
      <v-col cols="12" class="mb-8">
        <span class="text-body-1"
          >Adicione a imagem da sua marca e uma imagem para sua loja:
        </span>
      </v-col>
      <v-col cols="12">
        <div class="branding-wrapper">
          <img
            class="branding"
            src="@/assets/images/selfSignUp/example_branding.jpg"
          />
        </div>
      </v-col>
    </s-fields-wrapper>
    <s-fields-wrapper>
      <v-col cols="12" class="mt-4">
        <p class="text-h6 font-weight-regular">
          Faça o upload da <strong>Logo da sua loja:</strong>
        </p>
        <p class="text-body-1">
          A imagem deve ter boa resolução e estar em formato PNG ou JPG. O tamanho recomendado é de 100px por 100px.
        </p>
        <v-file-input
          :rules="storeRules"
          accept="image/png, image/jpeg, image/jpg"
          v-model="storeFiles"
          placeholder="Upload"
          outlined
          hide-details
        >
        </v-file-input>
      </v-col>
      <v-col cols="12" class="mt-4">
        <p class="text-h6 font-weight-regular">
          Faça o upload da <strong>Imagem destaque da sua loja:</strong>
        </p>
        <p class="text-body-1">
          A imagem deve ter boa resolução e estar em formato PNG ou JPG. O tamanho recomendado é de 728px por 376px.
        </p>
        <v-file-input
          :rules="brandRules"
          accept="image/png, image/jpeg, image/jpg"
          v-model="brandingFiles"
          placeholder="Upload"
          outlined
          hide-details
        >
        </v-file-input>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          :rules="acceptTermsRules"
          v-model="acceptTerms"
          isRequired
          label="Confirmo que as imagens adicionadas são referentes ao meu estabelecimento"
          color="success"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" v-if="error">
        <v-alert dismissible type="error">{{ error }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-btn
          :elevation="0"
          color="secondary"
          class="font-weight-bold"
          block
          x-large
          :loading="loading"
          @click="nextStep"
          >Salvar</v-btn
        >
      </v-col>
    </s-fields-wrapper>
  </s-main-container>
</template>

<script>
import SelfSignUpTitle from '@/components/selfSignUp/Title.vue'
import SelfSignUpMainContainer from '@/components/selfSignUp/Container.vue'
import SelfSignUpFieldsWrapper from '@/components/selfSignUp/FieldsWrapper.vue'
import EventsHelper from '@/util/EventsHelper'

const BRAND_IMAGE_MAX_SIZE = 300 * 1024
const STORE_IMAGE_MAX_SIZE = 300 * 1024

export default {
  name: 'SelfSignUpBranding',
  data: () => ({
    error: false,
    loading: false,
    storeFiles: [],
    brandingFiles: [],
    acceptTerms: false,
    acceptTermsRules: [
      (value) =>
        !!value ||
        'Você precisa confirmar que as imagens são referentes ao seu estabelecimento.'
    ],
    brandRules: [
      (value) =>
        !value ||
        value.size < BRAND_IMAGE_MAX_SIZE ||
        'A imagem precisa ter no máximo 300 KB!'
    ],
    storeRules: [
      (value) =>
        !value ||
        value.size < STORE_IMAGE_MAX_SIZE ||
        'A imagem precisa ter no máximo 300 KB!'
    ]
  }),
  components: {
    's-title': SelfSignUpTitle,
    's-main-container': SelfSignUpMainContainer,
    's-fields-wrapper': SelfSignUpFieldsWrapper
  },
  methods: {
    fieldsAreValid() {
      if (!this.acceptTerms) {
        this.error =
          'Você precisa confirmar que as imagens são referentes ao seu estabelecimento.'
        return false
      }

      if (this.storeFiles.length === 0) {
        this.error = 'Você precisa adicionar a logo da sua loja.'
        return false
      }

      if (this.brandingFiles.length === 0) {
        this.error = 'Você precisa adicionar a imagem destaque da sua loja.'
        return false
      }

      return true
    },
    async nextStep() {
      this.loading = true
      this.error = false
      if (!this.fieldsAreValid()) {
        this.loading = false
        return
      }
      try {
        await this.$store.dispatch('selfSignUp/submitBrandingData', {
          storeFiles: this.storeFiles,
          brandingFiles: this.brandingFiles
        })

        EventsHelper.getInstance().sendEvent('finish_selfsignup_successfully')

        await this.$store.dispatch('selfSignUp/closeSignUpOnboarding')
      } catch (_) {
        this.loading = false
        this.error = 'Alguma coisa deu errado. Tente novamente mais tarde'
        EventsHelper.getInstance().sendEvent('finish_selfsignup_with_errors')
      }
    }
  },
  mounted: function () {
    if (this.$store.state.selfSignUp.currentStep != 6) {
      this.$store.commit('selfSignUp/setCurrentStep', 6)
    }

    EventsHelper.getInstance().sendEvent('access_information_branding')
  }
}
</script>

<style scoped>
.branding-wrapper {
   width: 482px;
  height: 282px;
  position: relative;
  padding-bottom: 20px;
  user-select: none;
  pointer-events: none;
}
.branding {
  width: 482px;
  height: 282px;
  user-select: none;
  pointer-events: none;
}

</style>
