<template>
  <s-main-container>
    <s-fields-wrapper>
      <v-col cols="12">
        <s-title>Dados da sua loja</s-title>
      </v-col>
    </s-fields-wrapper>
    <s-fields-wrapper>
      <v-col cols="12">
        <s-label>Razão social*</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="name"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <s-label>Telefone (com DDD)</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="phone"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <s-label>CNPJ*</s-label>
        <v-text-field
          :loading="loading"
          class="mt-2"
          v-model="document"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <s-label>CEP*</s-label>
        <v-text-field
          :loading="loading"
          class="mt-2"
          v-model="zipCode"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <s-label>Rua*</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="streetName"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <s-label>Número:</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="streetNumber"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <s-label>Complemento (opcional)</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="complement"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <s-label>Estado</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="state"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <s-label>Cidade</s-label>
        <v-text-field
          :loading="loading"
          readonly
          class="mt-2"
          v-model="city"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <p class="edit-data-text font-italic">
          Caso necessite alterar algum dos campos, entre em contato através do
          nosso chat.
        </p>
      </v-col>
      <v-col cols="12">
        <v-btn
          :loading="loading"
          :elevation="0"
          color="secondary"
          class="font-weight-bold"
          block
          x-large
          @click="nextStep"
          >Próximo</v-btn
        >
      </v-col>
    </s-fields-wrapper>
  </s-main-container>
</template>

<script>
import SelfSignUpLabel from '@/components/selfSignUp/Label.vue'
import SelfSignUpTitle from '@/components/selfSignUp/Title.vue'
import SelfSignUpMainContainer from '@/components/selfSignUp/Container.vue'
import SelfSignUpFieldsWrapper from '@/components/selfSignUp/FieldsWrapper.vue'
import EventsHelper from '@/util/EventsHelper'

export default {
  name: 'SelfSignUpCompanyData',
  data: () => ({
    loading: false,
    name: '',
    phone: '',
    document: '',
    zipCode: '',
    streetName: '',
    streetNumber: '',
    complement: '',
    city: '',
    state: ''
  }),
  components: {
    's-label': SelfSignUpLabel,
    's-title': SelfSignUpTitle,
    's-main-container': SelfSignUpMainContainer,
    's-fields-wrapper': SelfSignUpFieldsWrapper
  },
  methods: {
    nextStep() {
      this.$store.dispatch('selfSignUp/nextStep')
      this.$router.push('/self-sign-up/responsible-data')
    }
  },
  mounted: async function () {
    this.loading = true
    if (this.$store.state.selfSignUp.currentStep != 1) {
      this.$store.commit('selfSignUp/setCurrentStep', 1)
    }

    const shouldRedirectToSelfSignUpOnboarding = await this.$store.dispatch(
      'shouldRedirectToSelfSignUpOnboarding'
    )

    if (!shouldRedirectToSelfSignUpOnboarding) {
      this.$router.push('/')
    }

    await this.$store.dispatch('selfSignUp/fetchCompanyData')

    if (this.$store.state.selfSignUp.companyData) {
      this.name = this.$store.state.selfSignUp.companyData.name
      this.phone = this.$store.state.selfSignUp.companyData.phone
      this.document = this.$store.state.selfSignUp.companyData.document
      this.zipCode = this.$store.state.selfSignUp.companyData.zipCode
      this.streetName = this.$store.state.selfSignUp.companyData.streetName
      this.streetNumber = this.$store.state.selfSignUp.companyData.streetNumber
      this.complement = this.$store.state.selfSignUp.companyData.complement
      this.city = this.$store.state.selfSignUp.companyData.city
      this.state = this.$store.state.selfSignUp.companyData.state
    }

    this.loading = false

    EventsHelper.getInstance().sendEvent('first_access_store_details')
  }
}
</script>

<style scoped>
.edit-data-text {
  font-size: 18px;
}
</style>
