<template>
  <div class="top-bar d-flex">
    <div class="d-flex align-center">
      <a href="https://parceiro.foodtosave.com.br">
        <img
          style="width: 64px; height: 48px"
          fluid
          class="logo"
          src="@/assets/images/logo_FTS_rebranding.png"
          alt="Food To Save Logo"
        />
      </a>
      <div class="show-mobile">
        <img
          class="btn-menu"
          @click="toggleMenu"
          src="@/assets/images/menu.svg"
          alt="menu"
        />
      </div>
      <div class="hide" v-if="showTopBarItems">
        <v-row align="center">
          <v-col cols="8" class="">
            <p class="text-sm-body-2 align-center mb-0 mt-4">
              Horário de Retirada:
            </p>
            <p>
              <strong>{{ formatTime(partner?.hora_inicio_retirada) }}</strong>
              às
              <strong>{{ formatTime(partner?.hora_final_retirada) }}</strong>
            </p>
          </v-col>
          <v-col cols="3" class="pa-0">
            <v-btn
              @click="openDialogChangeHour"
              rounded
              color="#697269"
              small
              style="margin: 10px; color: #ffffff"
              v-if="
                this.$router.history.current.path !== '/login' &&
                partner?.hora_inicio_retirada &&
                partner?.hora_final_retirada
              "
            >
              ALTERAR
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      class="hide"
      v-if="showTopBarItems || selfSignUp"
      style="position: absolute; right: 20px"
    >
      <v-row class="d-flex align-center">
        <v-col v-if="showTopBarItems">
          <v-row class="d-flex align-center">
            <div class="d-none d-lg-block">
              <v-menu transition="slide-y-transition" offset-y nudge-left="25">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="help-center-button"
                    color="#fff"
                    depressed
                    v-bind="attrs"
                    v-on="on"
                  >
                    CENTRAL DE AJUDA <v-icon right> mdi-chevron-down </v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="clickOpenFastGuide">
                    <v-list-item-icon class="mr-1">
                      <v-icon class="ma-0" right dark color="#FF6600"
                        >mdi-play-circle</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="help-center-text"
                        >Ver guia rápido</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="clickOpenHelpCenter">
                    <v-list-item-icon class="mr-1">
                      <v-icon class="ma-0" right dark color="#FF6600"
                        >mdi-help-circle</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="help-center-text"
                        >Acessar central de ajuda</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="d-lg-none">
              <v-menu transition="slide-y-transition" offset-y nudge-left="25">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon center dark color="#FF6600" size="35px">
                      mdi-help-circle
                    </v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="clickOpenFastGuide">
                    <v-list-item-icon class="mr-1">
                      <v-icon class="ma-0" right dark color="#FF6600"
                        >mdi-play-circle</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="help-center-text"
                        >Ver guia rápido</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="clickOpenHelpCenter">
                    <v-list-item-icon class="mr-1">
                      <v-icon class="ma-0" right dark color="#FF6600"
                        >mdi-help-circle</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="help-center-text"
                        >Acessar central de ajuda</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
        </v-col>
        <v-col v-if="showTopBarItems" class="d-lg-none d-lg-block pa-0">
          <div>
            <v-menu offset-y rounded="xl - xl">
              <template v-slot:activator="{ on, props }">
                <v-btn
                  fab
                  text
                  x-large
                  stacked
                  color="#FF6600"
                  v-bind="props"
                  v-on="on"
                  @click="sendGoogleAnalyticsEvent('see_notifications_list')"
                >
                  <v-badge
                    v-if="notificationsUnread > 0"
                    :content="notificationsUnread"
                  >
                    <v-icon>mdi-message-text-outline</v-icon>
                  </v-badge>
                  <v-icon v-if="notificationsUnread == 0"
                    >mdi-message-text-outline</v-icon
                  >
                </v-btn>
              </template>
              <v-list v-if="notifications.length > 0">
                <v-list-item
                  color="#838383"
                  style="font-size: 1.2em"
                  align-self-center
                  >Notificações</v-list-item
                >
                <v-list-item
                  v-for="(item, i) in notifications"
                  :key="i"
                  @click="openNotificationDetail(item, i)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-wrap"
                      style="font-weight: bold; font-size: 0.9em"
                      >{{ item.title }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="text-wrap"
                      style="font-size: 0.7em"
                      >{{ item.content }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="text-wrap"
                      style="font-size: 0.7em"
                      >{{
                        'Food To Save - ' + formatLocaleDate(item.created_at)
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <span v-if="item.viewedAt == null">
                      <v-icon x-small color="primary">mdi-circle</v-icon>
                    </span>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col v-if="showTopBarItems" class="d-lg-none d-lg-block pa-0">
          <v-btn
            fab
            text
            x-large
            @click="toggleMuteSound"
            :style="{ color: [audioIsEnabled ? '#FF6600' : null] }"
          >
            <v-icon v-if="audioIsEnabled">mdi-volume-high</v-icon>
            <v-icon v-else>mdi-volume-off</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-row>
            <div class="d-flex flex-row-reverse align-items-center">
              <div class="logo-container" :class="['logo-container-top']">
                <img fluid :src="partner?.url_logo" />
              </div>
              <div class="name-container">
                <p class="name mb-0" style="white-space: nowrap">
                  {{ this.partner?.nome }}
                </p>
              </div>
            </div>
          </v-row>
        </v-col>
        <v-btn fab text x-large @click="logout" :style="{ color: '#FF6600' }">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-row>
    </div>
    <div class="collapse-menu" v-if="showTopBarItems">
      <div class="d-flex" style="align-items: center">
        <v-btn
          fab
          text
          x-large
          @click="toggleMuteSound"
          :style="{ color: [audioIsEnabled ? '#FF6600' : null], font: [35] }"
        >
          <v-icon v-if="audioIsEnabled">mdi-volume-high</v-icon>
          <v-icon v-else>mdi-volume-off</v-icon>
        </v-btn>
        <div class="logo-container">
          <img fluid :src="partner?.url_logo" />
        </div>
      </div>
    </div>

    <v-dialog v-model="showOrderDetail" max-width="50rem">
      <order-detail
        v-if="this.orderId"
        :key="this.orderId"
        :orderId="this.orderId"
        :partnerId="this.partnerId"
      />
    </v-dialog>
    <v-dialog v-model="showDialogNewOrderArrived" max-width="25rem">
      <div class="dialog">
        <span class="text-h5 mb-4 d-flex justify-center">
          Chegou um novo pedido!
        </span>
        <button class="button" @click="closeDialogNewOrderArrived">
          Fechar
        </button>
      </div>
    </v-dialog>
    <v-dialog v-model="showTutorialVideo" width="35%" persistent>
      <div class="dialog-container">
        <iframe
          ref="partnerAdminTutorial"
          src="https://www.youtube.com/embed/wCLT1mnGS7A?si=UbgVNMgwXk0DC2gE&rel=0&enablejsapi=1"
          title="Tutorial Painel do Parceiro - Food To Save"
          class="tutorial-video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <button class="button" @click="closeTutorialVideoDialog">Fechar</button>
      </div>
    </v-dialog>
    <v-dialog
      id="dialog-change-hour"
      style=""
      max-width="25rem"
      v-model="showDialogChangeHour"
    >
      <div id="dialog-change-hour__content" class="dialog">
        <v-row>
          <v-col class="text-center">
            <h1>Horário de Retirada</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>Início:</v-col>
          <v-col
            ><date-picker
              v-model="horaInicioRetirada"
              type="time"
              format="HH:mm"
              valueType="format"
            ></date-picker
          ></v-col>
        </v-row>
        <v-row>
          <v-col>Final:</v-col>
          <v-col
            ><date-picker
              v-model="horaFinalRetirada"
              type="time"
              format="HH:mm"
              valueType="format"
            ></date-picker
          ></v-col>
        </v-row>
        <button
          class="button"
          :disabled="
            horaInicioRetirada === partner?.hora_inicio_retirada &&
            horaFinalRetirada === partner?.hora_final_retirada
          "
          @click="updateHour"
          :loading="loadingHours"
        >
          Salvar
          <v-progress-circular
            v-if="loadingHours"
            indeterminate
            color="white"
            :size="25"
          />
        </button>
        <erro-msg :erros="erros"></erro-msg>
      </div>
    </v-dialog>
    <v-dialog
      max-width="25rem"
      v-model="showDialogConfirmChangeHourOngoingTakeoutError"
    >
      <div class="dialog">
        <v-row>
          <v-col class="text-center">
            <p>
              Notamos que existem pedidos realizados que ainda não foram
              coletados, deseja alterar o horário mesmo assim?
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <button
              class="button"
              @click="updateHourWithOngoingOrders"
              :loading="loadingHours"
            >
              Sim
              <v-progress-circular
                v-if="loadingHours"
                indeterminate
                color="white"
                :size="25"
              />
            </button>
          </v-col>
          <v-col>
            <button
              class="button"
              @click="closeUpdateHourWithOngoingOrdersDialog"
            >
              Não
            </button>
          </v-col>
        </v-row>
        <erro-msg :erros="erros"></erro-msg>
      </div>
    </v-dialog>
    <audio id="myAudio" volume="1" muted>
      <source src="@/assets/trim.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
import { formatLocaleDate } from '@/util/Formater.js'
import OrderDetail from '@/components/order/OrderDetail.vue'
import { mapMutations, mapState, mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import Erro from '@/components/shared/Erro.vue'
import { resetSession } from '@/plugins/axios'
import EventsHelper from '@/util/EventsHelper'

export default {
  name: 'TopBar',
  components: {
    OrderDetail: OrderDetail,
    DatePicker: DatePicker,
    'erro-msg': Erro
  },
  data: () => ({
    showMenu: false,
    orderId: null,
    deviceWidth: 0,
    notificationsLoading: false,
    showOrderDetail: false,
    notifications: [],
    notificationsUnread: 0,
    showTopBarItems: null,
    selfSignUp: false,
    showDialogChangeHour: false,
    partner: null,
    horaInicioRetirada: null,
    horaFinalRetirada: null,
    loadingHours: false,
    audioIsEnabled: true,
    showDialogNewOrderArrived: false,
    erros: [],
    showDialogConfirmChangeHourOngoingTakeoutError: false,
    showTutorialVideo: false
  }),
  computed: {
    items() {
      return this.notifications
    },
    ...mapState(['estabelecimento']),
    ...mapMutations(['setEstabelecimento'])
  },

  methods: {
    formatLocaleDate,
    openNotificationDetail: function (partnerNotification, index) {
      if (partnerNotification.data) {
        var notification = JSON.parse(partnerNotification.data)
        if (
          partnerNotification.notification_action === 'ORDER' &&
          notification.hasOwnProperty('orderId')
        ) {
          this.orderId = notification.orderId
          this.showOrderDetail = true
        }
      }

      if (partnerNotification.viewedAt == null) {
        this.$set(
          this.notifications,
          index,
          Object.assign({}, partnerNotification, { viewedAt: Date.now() })
        )
        this.readNotification(partnerNotification)
        this.notificationsUnread = this.notificationsUnread - 1
      }
      this.sendGoogleAnalyticsEvent('open_notifications')
    },
    logout() {
      try {
        const persistentStoredKeys = ['food_onboarding_shown']
        const storedKeysToBeDeleted = Object.keys(localStorage).filter(
          (key) => !persistentStoredKeys.includes(key)
        )
        storedKeysToBeDeleted.forEach((key) => {
          localStorage.removeItem(key)
        })
      } catch (_) {
        localStorage.clear()
      }
      this.$store.commit('logout')
      resetSession()
    },
    toggleMenu() {
      const lateralMenuContent = document.querySelector(
        '.lateral-container-content'
      )
      if (!this.showMenu) {
        lateralMenuContent.style.display = 'block'
        lateralMenuContent.classList.remove('hidden-menu')
        lateralMenuContent.classList.add('shown-menu')
      } else {
        lateralMenuContent.style.display = 'none'
        lateralMenuContent.classList.add('hidden-menu')
      }
      this.showMenu = !this.showMenu
    },
    toggleMuteSound() {
      this.audioIsEnabled = !this.audioIsEnabled
      this.sendGoogleAnalyticsEvent('received_order_sound')
    },
    getNotifications() {
      this.erros = []
      this.notificationsLoading = true
      this.$http
        .get(`/v1/partners/${this.partner?.id}/notifications`)
        .then((response) => {
          this.notifications = response.data
          this.notificationsUnread = this.notifications.length
        })
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.notificationsLoading = false
        })

      return this.notifications
    },
    closeUpdateHourWithOngoingOrdersDialog() {
      this.erros = []
      this.showDialogConfirmChangeHourOngoingTakeoutError = false
    },
    readNotification(notification) {
      this.erros = []
      this.notificationsLoading = true

      const request = {
        id: notification.id,
        viewed_at: new Date()
      }

      this.$http
        .put(`/v1/partners/${this.partnerId}/notifications`, request)
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.notificationsLoading = false
        })
      return this.notifications
    },
    sendGoogleAnalyticsEvent(eventName) {
      EventsHelper.getInstance().sendEvent(eventName)
    },
    clickOpenFastGuide() {
      this.sendGoogleAnalyticsEvent('show_tutorial_video')
      this.showTutorialVideo = true
    },
    clickOpenHelpCenter() {
      window.open('https://link.foodtosave.com.br/centraldeajuda', '_blank')
      this.sendGoogleAnalyticsEvent('help_center')
    },
    openDialogChangeHour() {
      this.sendGoogleAnalyticsEvent('opening_hours')
      this.showDialogChangeHour = true
    },
    updateHour() {
      this.erros = []

      if (!this.horaInicioRetirada || !this.horaFinalRetirada) {
        this.erros.push('Informe os novos horários.')
      }

      if (this.erros && this.erros.length > 0) {
        return
      }

      const data = {
        hora_inicio_retirada: this.horaInicioRetirada,
        hora_final_retirada: this.horaFinalRetirada
      }

      EventsHelper.getInstance().sendEvent('opening_hours_edit', {
        partner_id: this.estabelecimento.id,
        hour_change: data.hora_inicio_retirada
      })

      this.loadingHours = true
      this.$http
        .put(`/v1/parceiro/${this.estabelecimento.id}/hora_retirada`, data)
        .then((response) => {
          this.partner.hora_inicio_retirada = this.horaInicioRetirada
          this.partner.hora_final_retirada = this.horaFinalRetirada
          this.$store.commit('setEstabelecimento', this.partner)
          this.showDialogChangeHour = false
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.showDialogConfirmChangeHourOngoingTakeoutError = true
            this.showDialogChangeHour = false
          } else {
            this.erros = []
            this.erros.push(error.response.data)
          }
        })
        .finally(() => {
          this.loadingHours = false
        })
    },
    updateHourWithOngoingOrders() {
      this.$http
        .put(`/v1/parceiro/${this.estabelecimento.id}/hora_retirada`, {
          hora_inicio_retirada: this.horaInicioRetirada,
          hora_final_retirada: this.horaFinalRetirada,
          updateWithTakeOutOrders: true
        })
        .then(() => {
          this.partner.hora_inicio_retirada = this.horaInicioRetirada
          this.partner.hora_final_retirada = this.horaFinalRetirada
          this.$store.commit('setEstabelecimento', this.partner)
        })
        .catch((error) => {
          this.erros = []
          this.erros.push(error.response.data)
        })
        .finally(() => {
          this.loadingHours = false
          this.showDialogChangeHour = false
          this.showDialogConfirmChangeHourOngoingTakeoutError = false
        })
    },
    showOrderArrivedPopup() {
      this.showDialogNewOrderArrived = true
      if (this.audioIsEnabled) {
        try {
          const lastTimeSoundPlayed =
            this.$store.getters.getLastTimeOrderArrivedPlayed

          const fifteenSecondsInMilliseconds = 15 * 1000
          const playedTime = new Date().getTime()
          const timeDifferenceBetweenLastPlay = playedTime - lastTimeSoundPlayed

          if (timeDifferenceBetweenLastPlay >= fifteenSecondsInMilliseconds) {
            document.getElementById('myAudio').volume = 1
            document.getElementById('myAudio').play()
            this.$store.commit('lastTimeOrderArrivedSoundPlayed', playedTime)
          }
        } catch (error) {
          EventsHelper.getInstance().sendEvent('error_playing_audio', { error })
          EventsHelper.getInstance().exception({
            description: 'error_playing_audio',
            fatal: false
          })
        }
      }
    },
    closeDialogNewOrderArrived() {
      this.showDialogNewOrderArrived = false
    },
    closeTutorialVideoDialog() {
      this.showTutorialVideo = false
    },
    formatTime(timeString) {
      if (!timeString) return ''
      const [hours, minutes] = timeString.split(':')
      return `${hours}:${minutes}`
    }
  },

  mounted() {
    this.$root.$on('newOrderArrived', (order) => {
      this.showOrderArrivedPopup()
    })

    this.partner = this.$store.getters.getEstabelecimento
    this.showTopBarItems =
      !!this.partner && this.$router.history.current.path !== '/login'

    this.horaInicioRetirada = this.partner?.hora_inicio_retirada
    this.horaFinalRetirada = this.partner?.hora_final_retirada
    this.showTutorialVideo =
      this.$store.state.selfSignUp?.showTutorialVideo ?? false

    this.$root.$on('callNotificationModal', (notificationData) => {
      this.notifications.unshift(notificationData)
      this.notificationsUnread = this.notificationsUnread + 1
      return this.notifications
    })
  }
}
</script>

<style scoped>
@import './TopBar.scss';
</style>
